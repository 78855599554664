import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPartner } from '@apis/partner';
import { PageResponse } from '@models/api';
import { Partner } from '@models/partner';

export const usePartnerDetail = (partnerId: number) => {
  const queryOptions: UseQueryOptions<PageResponse<Partner>, Error> = {
    queryKey: ['partnerDetail', partnerId],
    queryFn: () => {
      return getPartner(partnerId);
    },
    enabled: !!partnerId,
  };
  return useQuery(queryOptions);
};
