import { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import TextInput from '@components/common/TextInput';
import {  ProductStatus, RequiredDataType } from '@models/product';
import TextAreaInput from '@components/common/TextAreaInput';
import { Divider } from '@material-ui/core';
import Label from '@components/common/Label';
import CheckBox from '@components/common/CheckBox';
import { FormControl } from '@mui/base/FormControl';
import AddCoverageContent from '@components/product/AddCoverageContent';
import { AddCircleOutline } from '@mui/icons-material';
import Chip from '@components/common/Chip';
import Modal from '@components/common/Modal';
import Button from '@components/common/Button';
import APIInput from '@components/product/APIInput';
import DataInput from '@components/product/DataInput';
import {
  createProduct,
  ProductSaleType,
  ProductType,
  ProductUpsertRequest,
} from '@apis/product';
import { useNavigate } from 'react-router-dom';
import { useAllStockList } from '@queries/stocks/useAllStockList';
import useAsyncEffect from '@hooks/useAsyncEffect';
import Select, { SelectValue } from '@components/common/Select';
import { getStock, StockSupplierTaskType } from '@apis/stock';
import { useAllOperatorList } from '@queries/operators/useAllOperatorList';
import { useContinentList } from '@queries/continents/useContinentList';
import { useCountryList } from '@queries/countries/useCountryList';
import { alertMissingValues, isNullOrValidJson } from '@utils/common';
import { generateProductCode } from '@utils/code';
import RadioButtonGroup from '@components/common/RadioButtonGroup';

const AddProduct = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState<ProductUpsertRequest>({
    productCode: generateProductCode(),
    productSaleType: ProductSaleType.REAL_TIME,
    productType: ProductType.ESIM,
    description: '',
    specification: {
      dayCount: 0,
      isUnlimitedData: true,
      isVoiceCallSupported: false,
      dataCapacity: {
        quantity: 0,
        unit: 'GB',
        refreshPeriodUnit: 'NEVER',
        refreshPeriodValue: 0,
      },
      isFup: false,
    },
    requiredData: {
      requiredFields: [],
    },
    apiParameter: undefined,
    productStatus: ProductStatus.ACTIVE,
    productCoverages: [],
    productName: '',
    stockId: 0,
    price: 0,
  });

  const [defaultApiParameter, setDefaultApiParameter] = useState<string | null>(
    null
  );

  const [isApiInputNeeded, setIsApiInputNeeded] = useState(false);

  const [addCoverage, setAddCoverage] = useState(false);
  const [stockOptionList, setStockOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);

  const { data: countriesList } = useCountryList();
  const { data: continentsList } = useContinentList();
  const { data: operatorsList } = useAllOperatorList();

  const handleChangeInput = (
    name: keyof ProductUpsertRequest,
    value: string | boolean | number | null
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeRequired = (name: RequiredDataType, checked: boolean) => {
    setForm((prev) => ({
      ...prev,
      requiredData: {
        ...prev.requiredData,
        requiredFields: checked
          ? [...prev.requiredData.requiredFields, name]
          : prev.requiredData.requiredFields.filter((r) => r !== name),
      },
    }));
  };

  const handleChangeSpecification = (
    name: keyof ProductUpsertRequest['specification'],
    value: string | boolean | number | null
  ) => {
    setForm((prev) => ({
      ...prev,
      specification: { ...prev.specification, [name]: value },
    }));
  };

  const handleChangeSpecificationData = (
    name: keyof ProductUpsertRequest['specification']['dataCapacity'],
    value: number | string
  ) => {
    setForm((prev) => ({
      ...prev,
      specification: {
        ...prev.specification,
        dataCapacity: {
          ...prev.specification.dataCapacity,
          [name]: value,
        },
      },
    }));
  };

  const handleClickAddCoverage = () => {
    setAddCoverage(true);
  };

  const handleDeleteCoverage = (index: number) => {
    setForm((prev) => ({
      ...prev,
      productCoverages: prev.productCoverages.filter((_, i) => i !== index),
    }));
  };

  const handleCancel = () => {
    if (!!window.confirm('Do you want to cancel?')) {
      navigate(-1);
    }
  };

  const handleAdd = async () => {
    form.productCode = generateProductCode();
    // validate
    const hasMissingValues = alertMissingValues(form);
    if (hasMissingValues) return;

    if (!isNullOrValidJson('apiParameter', form.apiParameter)) {
      return;
    }

    const { message } = await createProduct(form);

    if (message === 'OK') {
      alert('Product added successfully');
      navigate('/products');
    } else {
      alert(message);
    }
  };

  const { data: stocksData } = useAllStockList();

  useAsyncEffect(async () => {
    if (!stocksData) return;
    setStockOptionList(
      stocksData?.data.map((s) => ({
        label: s.stockCode,
        value: s.stockId,
      }))
    );
  }, [stocksData]);

  useAsyncEffect(async () => {
    if (!form.stockId) return;
    const { data } = await getStock(form.stockId);
    if (!data[0]) return;
    // supplierTaskType이 API인 경우에만 isApiInputNeeded = true
    setIsApiInputNeeded(
      data[0]?.supplierTaskType === StockSupplierTaskType.API
    );
    if (data[0]?.supplier.supplierName === 'CMI') {
      setDefaultApiParameter(
        JSON.stringify(
          {
            bundleId: '',
          },
          null,
          2
        )
      );
    } else if (data[0]?.supplier.supplierName === 'Uhuibao') {
      setDefaultApiParameter(
        JSON.stringify(
          {
            packageId: '',
          },
          null,
          2
        )
      );
    } else if (data[0]?.supplier.supplierName === 'Joytel') {
      setDefaultApiParameter(
        JSON.stringify(
          {
            productCode: '',
          },
          null,
          2
        )
      );
    } else if (data[0]?.supplier.supplierName === 'TJ_USA') {
      setDefaultApiParameter(
        JSON.stringify(
          {
            carrier: '',
            plan: '',
            day: '',
          },
          null,
          2
        )
      );
    } else if (data[0]?.supplier.supplierName.startsWith('LG')) {
      setDefaultApiParameter(
        JSON.stringify({
          productCode: '',
        })
      );
    }
  }, [form.stockId]);

  return (
    <>
      <Typography variant="h4" component="h4">
        New Product
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        mb={4}
        mt={4}
      >
        <TextInput
          label={'Product Name'}
          value={form.productName}
          onChange={(e) => handleChangeInput('productName', e.target.value)}
          placeholder="Enter product name"
          layout="horizontal"
          labelWidth={150}
          inputWidth={500}
        />
        <TextAreaInput
          label={'Description'}
          value={form.description}
          onChange={(e) => handleChangeInput('description', e.target.value)}
          multiline
          placeholder="Describe the product"
          layout="horizontal"
          labelWidth={150}
          inputWidth={500}
          rows={8}
        />

        {/* Specification */}
        <FormControl>
          <Box display={'flex'} flexDirection={'row'} gap={2}>
            <Box width={150} display={'flex'}>
              <Label>Specification</Label>
            </Box>
            <Box display="flex" flexDirection={'column'} gap={2}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={5}
                alignItems={'center'}
              >
                <TextInput
                  value={form.specification.dayCount}
                  onChange={(e) =>
                    handleChangeSpecification('dayCount', e.target.value)
                  }
                  layout="horizontal"
                  labelWidth={150}
                  inputWidth={200}
                  type="number"
                  unit="day(s)"
                />
                <CheckBox
                  label={'Voice Call'}
                  value={form.specification.isVoiceCallSupported}
                  onCheck={(checked) =>
                    handleChangeSpecification('isVoiceCallSupported', checked)
                  }
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={5}
                alignItems={'center'}
              >
                <CheckBox
                  label={'Unlimited Data'}
                  value={form.specification.isUnlimitedData}
                  onCheck={(checked) => {
                    handleChangeSpecification('isUnlimitedData', checked);
                    handleChangeSpecificationData('quantity', 0);
                    handleChangeSpecificationData('unit', 'MB');
                  }}
                />
                {/* Data Select with Unit */}
                <DataInput
                  value={form.specification.dataCapacity.quantity}
                  unit={form.specification.dataCapacity.unit}
                  onChangeUnit={(unit) =>
                    handleChangeSpecificationData('unit', unit)
                  }
                  onChangeValue={(value) =>
                    handleChangeSpecificationData('quantity', value)
                  }
                  isDisabled={form.specification.isUnlimitedData}
                />
                <CheckBox
                  label={'FUP'}
                  value={form.specification.isFup}
                  onCheck={(checked) =>
                    handleChangeSpecification('isFup', checked)
                  }
                />
              </Box>
              <Box display={'flex'} flexDirection={'row'} gap={5}>
                {'Refresh Every'}
                <TextInput
                  value={form.specification.dataCapacity.refreshPeriodValue}
                  layout="horizontal"
                  labelWidth={80}
                  type="number"
                  disabled={
                    form.specification.isUnlimitedData ||
                    form.specification.dataCapacity.refreshPeriodUnit ===
                      'NEVER'
                  }
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      return;
                    }
                    handleChangeSpecificationData(
                      'refreshPeriodValue',
                      e.target.value
                    );
                  }}
                  inputWidth={80}
                />
                <Select
                  value={form.specification.dataCapacity.refreshPeriodUnit}
                  optionList={['DAYS', 'WEEKS', 'MONTHS', 'YEARS', 'NEVER'].map(
                    (o) => ({
                      label: o,
                      value: o,
                    })
                  )}
                  setValue={(value: SelectValue) => {
                    handleChangeSpecificationData(
                      'refreshPeriodUnit',
                      value as string
                    );
                  }}
                  disabled={form.specification.isUnlimitedData}
                />
              </Box>
            </Box>
          </Box>
        </FormControl>
        {/* Coverage */}
        <FormControl>
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={2}
            alignItems={'center'}
          >
            <Box width={150} display={'flex'}>
              <Label>Coverage</Label>
            </Box>
            {form.productCoverages.map((c) => (
              <Chip
                label={`${
                  countriesList?.data.find((co) => co.countryId === c.countryId)
                    ?.englishName
                }`}
                key={c.countryId}
                onDelete={() =>
                  handleDeleteCoverage(form.productCoverages.indexOf(c))
                }
              />
            ))}
            <Box display={'flex'} flexDirection={'row'} gap={5}>
              <IconButton onClick={handleClickAddCoverage}>
                <AddCircleOutline color="primary" />
              </IconButton>
            </Box>
          </Box>
        </FormControl>
        {/* Price */}
        <TextInput
          label={'Price'}
          value={form.price.toString()}
          onChange={(e) => {
            if (/(?!0\d)^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
              handleChangeInput('price', e.target.value);
            }
          }}
          layout="horizontal"
          labelWidth={150}
          inputWidth={200}
          type="number"
          unit="USD"
        />
      </Box>
      <Divider />
      <FormControl>
        <Box
          display={'flex'}
          flexDirection={'row'}
          mb={2}
          mt={2}
          alignItems={'center'}
          gap={2}
        >
          <Box width={150} display={'flex'}>
            <Label>Required</Label>
          </Box>
          <Box gap={5} display={'flex'} flexDirection={'row'}>
            <CheckBox
              label={'Activation Date'}
              value={form.requiredData.requiredFields.includes(
                RequiredDataType.ACTIVATION_DATE
              )}
              onCheck={(checked) =>
                handleChangeRequired(RequiredDataType.ACTIVATION_DATE, checked)
              }
            />
            <CheckBox
              label={'Days (validity)'}
              value={form.requiredData.requiredFields.includes(
                RequiredDataType.VALIDITY_DAYS
              )}
              onCheck={(checked) =>
                handleChangeRequired(RequiredDataType.VALIDITY_DAYS, checked)
              }
            />
            <CheckBox
              label={'IMEI'}
              value={form.requiredData.requiredFields.includes(
                RequiredDataType.IMEI
              )}
              onCheck={(checked) =>
                handleChangeRequired(RequiredDataType.IMEI, checked)
              }
            />
            <CheckBox
              label={'EID'}
              value={form.requiredData.requiredFields.includes(
                RequiredDataType.EID
              )}
              onCheck={(checked) =>
                handleChangeRequired(RequiredDataType.EID, checked)
              }
            />
            <CheckBox
              label={'User Name'}
              value={form.requiredData.requiredFields.includes(
                RequiredDataType.CUSTOMER_NAME
              )}
              onCheck={(checked) =>
                handleChangeRequired(RequiredDataType.CUSTOMER_NAME, checked)
              }
            />
            <CheckBox
              label={'Passport'}
              value={form.requiredData.requiredFields.includes(
                RequiredDataType.PASSPORT_DATA
              )}
              onCheck={(checked) =>
                handleChangeRequired(RequiredDataType.PASSPORT_DATA, checked)
              }
            />
          </Box>
        </Box>
      </FormControl>
      <Divider />
      <FormControl>
        <Box
          display={'flex'}
          flexDirection={'row'}
          mb={2}
          mt={2}
          alignItems={'center'}
          gap={2}
        >
          <Box width={150} display={'flex'}>
            <Label>Product Status</Label>
          </Box>
          <Box gap={5} display={'flex'} flexDirection={'row'}>
            <RadioButtonGroup
              value={form.productStatus}
              onChange={(value) => {
                handleChangeInput('productStatus', value as ProductStatus);
              }}
              options={Object.keys(ProductStatus).map((s) => ({
                label: s,
                value: s,
              }))}
              layout="horizontal"
            />
          </Box>
        </Box>
      </FormControl>
      <Divider />
      <Box
        display={'flex'}
        flexDirection={'row'}
        mb={2}
        mt={2}
        alignItems={'center'}
        gap={2}
      >
        <Box width={150} display={'flex'}>
          <Label>Product Sale Type</Label>
        </Box>
        <Box ml={2} display={'flex'} flexDirection={'row'}>
          <Select
            optionList={Object.values(ProductSaleType).map((s) => ({
              label: s,
              value: s,
            }))}
            placeholder="Enter the Product Sale Type"
            layout="horizontal"
            value={form.productSaleType}
            labelWidth={150}
            setValue={(value) => {
              handleChangeInput('productSaleType', value as ProductSaleType);
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        display={'flex'}
        flexDirection={'row'}
        mb={2}
        mt={2}
        alignItems={'center'}
        gap={2}
      >
        <Box width={150} display={'flex'}>
          <Label>Product Type</Label>
        </Box>
        <Box ml={2} display={'flex'} flexDirection={'row'}>
          <Select
            optionList={Object.values(ProductType).map((s) => ({
              label: s,
              value: s,
            }))}
            placeholder="Enter the Product Type"
            layout="horizontal"
            value={form.productType}
            labelWidth={150}
            setValue={(value) => {
              handleChangeInput('productType', value as ProductType);
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        flexDirection={'row'}
        mb={4}
        mt={4}
        gap={2}
      >
        <Select
          label={'Stock Code'}
          optionList={stockOptionList}
          placeholder="Enter the Stock Code"
          layout="horizontal"
          labelWidth={150}
          value={
            stockOptionList.find((c) => c.value === form.stockId)?.value || null
          }
          setValue={(value) => handleChangeInput('stockId', Number(value))}
        />
        <Box ml={10}>
          {isApiInputNeeded && (
            <APIInput
              value={form.apiParameter || defaultApiParameter}
              setValue={(value) =>
                handleChangeInput('apiParameter', String(value))
              }
              isDisabled={false}
            />
          )}
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        gap={2}
      >
        <Button text={'CANCEL'} onClick={handleCancel} color={'error'} />
        <Button text={'ADD'} onClick={handleAdd} />
      </Box>
      <Modal
        open={addCoverage}
        onClose={() => {
          setAddCoverage(false);
        }}
      >
        <AddCoverageContent
          onClose={() => {
            setAddCoverage(false);
          }}
          onAdd={(coverage) => {
            setForm((prev) => ({
              ...prev,
              productCoverages: [...prev.productCoverages, coverage],
            }));
            setAddCoverage(false);
          }}
        />
      </Modal>
    </>
  );
};

export default AddProduct;
